<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="700px"
  >
    <v-card>
      <v-card-title>
        Instantmessage
      </v-card-title>
      <v-card-text>
        <v-form ref="instantmessage">
          <v-text-field
            v-model="instant.title"
            v-shortkey.avoid
            label="title"
            hint="only displayed internally"
            :rules="[rules.required()]"
          />
          <v-textarea
            v-model="instant.description"
            v-shortkey.avoid
            rows="5"
            label="displaytext"
            :rules="[rules.required()]"
          />
        </v-form>

        <v-data-table
          v-if="instant.id"
          :headers="headers"
          :items="instant.instantmessageTranslations"
        >
          <template #item.actions="{item}">
            <v-btn
              icon
              @click="edit(item)"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialogCloneTo(item)"
                >
                  <v-icon>
                    mdi-export
                  </v-icon>
                </v-btn>
              </template>
              <span>Copy to other Profile</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialogCloneFrom(item)"
                >
                  <v-icon>
                    mdi-exit-to-app
                  </v-icon>
                </v-btn>
              </template>
              <span>Copy from other Profile</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="cloneToEmpty(item.id)"
                >
                  <v-icon>
                    mdi-checkbox-multiple-blank-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Copy to empty Profiles</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="instant.id && isHost"
          depressed
          small
          color="primary"
          @click="createTranslation"
        >
          new translation
        </v-btn>

        <v-btn
          v-if="!isNew && !isHost"
          depressed
          small
          color="primary"
          @click="addProfiles"
        >
          AddProfiles
        </v-btn>

        <v-btn
          depressed
          small
          color="primary"
          @click="save"
        >
          save
        </v-btn>
        <v-btn
          v-if="instant.id"
          depressed
          small
          color="primary"
          @click="deleteItem"
        >
          delete
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          @click="close"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
    <InstantmessageTranslationDetail
      :visible="dialog_instantmessageTranslation"
      :is-host="isHost"
      :is-new="isNewTranslation"
      :data="selected_translation"
      @close="dialog_instantmessageTranslation = false"
      @reload="loadInstantmessage"
    />
    <CloneToDialog
      :visible="dialog_clone_to"
      :project-id="selected_project_id"
      @close="dialog_clone_to = false"
      @cloneto="cloneto"
    />
    <CloneFromDialog
      :visible="dialog_clone_from"
      :project-id="selected_project_id"
      @close="dialog_clone_from = false"
      @clonefrom="clonefrom"
    />
  </v-dialog>
</template>

<script>
import {mapMutations, mapState, mapActions} from 'vuex'
import _ from 'lodash'
import InstantmessageTranslationDetail from './InstantmessageTranslationDetail'
import InstantmessageTranslation from '../../../types/InstantmessageTranslation'
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin'
import instantmessageService from '@/services/Instantmessage.service'
import hostService from '@/services/Host.service'
import CloneToDialog from '@/components/PerformanceManager/Instantmessages/CloneToDialog'
import CloneFromDialog from '@/components/PerformanceManager/Instantmessages/CloneFromDialog'

export default {
  name: 'InstantmessageDetail',
  components: {
    CloneFromDialog,
    CloneToDialog,
    InstantmessageTranslationDetail
  },
  mixins: [ValidationRulesMixin],
  props: {
    visible: Boolean,
    isHost: Boolean,
    isNew: Boolean,
    data: {
      type: Object,
      default: Object
    },
  },
  data() {
    return {
      instant: {},
      dialog_instantmessageTranslation: false,
      dialog_clone_to: false,
      dialog_clone_from: false,
      selected_translation: {},
      selected_clone: null,
      selected_project_id: null,
      isNewTranslation: false,
      headers: [
        {
          text: 'language',
          value: 'language.description'
        },
        {
          text: 'title',
          value: 'title'
        },
        {
          text: 'description',
          value: 'description'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right'
        },
      ]
    }
  },
  computed: {
    ...mapState('instantmessage', ['instantmessage', 'dialogs']),


  },
  watch: {
    visible(val) {
      if (val) {
        this.cloneData()
      }
    }
  },
  mounted() {
  },
  methods: {
    async loadInstantmessage() {
      this.instant = await instantmessageService.loadInstantmessageById(this.instant.id)
    },

    close() {
      this.$emit('reload')
      this.$emit('close')
    },
    cloneData() {
      this.instant = _.clone(this.data)
    },

    createTranslation() {
      this.isNewTranslation = true
      this.selected_translation = new InstantmessageTranslation(this.instant.id)
      this.dialog_instantmessageTranslation = true
    },

    edit(item) {
      this.isNewTranslation = false
      this.selected_translation = _.clone(item)
      this.dialog_instantmessageTranslation = true
    },

    async save() {
      if (this.$refs.instantmessage.validate()) {
        if (this.isNew) {
          try {
            await instantmessageService.create(this.instant)
            this.$toast('Instantmessage created', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            this.$emit('reload')
            this.$emit('close')
          } catch (e) {
            this.$toast('create failed', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }
        } else {
          try {
            await instantmessageService.update(this.instant.id, this.instant)
            this.$toast('Instantmessage updated', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            this.$emit('reload')
            this.$emit('close')
          } catch (e) {
            this.$toast('update failed', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }
        }
      }
    },
    async deleteItem() {
      this.$confirm('delete Instantmessage', {
        type: 'delete'
      })
        .then(async value => {
          if (value) {
            try {
              await instantmessageService.delete(this.instant.id)
              this.$toast('Instantmessage created', {
                type: 'success',
                top: true,
                right: true,
                timeout: 3000,
              })
              this.$emit('reload')
              this.$emit('close')
            } catch (e) {
              this.$toast('delete failed', {
                type: 'error',
                top: true,
                right: true,
                timeout: 3000,
              })
            }
            this.deleteInstantmessage(this.instant)
          }
        })
    },
    async addProfiles() {
      try {
        await instantmessageService.createTranslationsPerProfile(this.instant.id, this.instant.projectId)
        await this.loadInstantmessage()
      } catch (e) {
        this.$toast('copy failed', {
          type: 'error',
          top: true,
          right: true,
          timeout: 3000,
        })
      }
    },

    openDialogCloneTo(item) {
      this.selected_project_id = this.instant.projectId
      this.selected_clone = item.id
      this.dialog_clone_to = true;
    },
    openDialogCloneFrom(item) {
      this.selected_project_id = this.instant.projectId
      this.selected_clone = item.id;
      this.dialog_clone_from = true;
    },
    cloneto(data) {
      const from = this.instant.instantmessageTranslations.find(e => e.id === this.selected_clone)
      const to = this.instant.instantmessageTranslations.find(e => e.profile_id === data)
      to.description = _.clone(from.description)
      this.dialog_clone_to = false;

      this.$toast('Copied but not saved', {
        type: 'info',
        time: 2000
      })
    },

    clonefrom(data) {
      console.log('data', data)
      const to = this.instant.instantmessageTranslations.find(e => e.id === this.selected_clone)
      const from = this.instant.instantmessageTranslations.find(e => e.profile_id === data)
      to.description = _.clone(from.description)
      this.dialog_clone_from = false;

      this.$toast('Copied but not saved', {
        type: 'info',
        time: 2000
      })

    },

    cloneToEmpty(data) {
      const from = this.instant.instantmessageTranslations.find(e => e.id === data)
      const tos = this.instant.instantmessageTranslations.filter(e => e.description === null || e.description === '')

      console.log(from, tos, this.instant.instantmessageTranslations)

      tos.forEach(e => {
        e.description = _.clone(from.description)
      })
      this.$toast('Copied but not saved', {
        type: 'info',
        time: 2000
      })
    }

  }
}
</script>

<style scoped>

</style>
