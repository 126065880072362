var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.visible,"persistent":"","max-width":"700px"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Instantmessage ")]),_c('v-card-text',[_c('v-form',{ref:"instantmessage"},[_c('v-text-field',{directives:[{name:"shortkey",rawName:"v-shortkey.avoid",modifiers:{"avoid":true}}],attrs:{"label":"title","hint":"only displayed internally","rules":[_vm.rules.required()]},model:{value:(_vm.instant.title),callback:function ($$v) {_vm.$set(_vm.instant, "title", $$v)},expression:"instant.title"}}),_c('v-textarea',{directives:[{name:"shortkey",rawName:"v-shortkey.avoid",modifiers:{"avoid":true}}],attrs:{"rows":"5","label":"displaytext","rules":[_vm.rules.required()]},model:{value:(_vm.instant.description),callback:function ($$v) {_vm.$set(_vm.instant, "description", $$v)},expression:"instant.description"}})],1),(_vm.instant.id)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.instant.instantmessageTranslations},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogCloneTo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-export ")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy to other Profile")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogCloneFrom(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-exit-to-app ")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy from other Profile")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.cloneToEmpty(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-checkbox-multiple-blank-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy to empty Profiles")])])]}}],null,false,2537249136)}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),(_vm.instant.id && _vm.isHost)?_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":_vm.createTranslation}},[_vm._v(" new translation ")]):_vm._e(),(!_vm.isNew && !_vm.isHost)?_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":_vm.addProfiles}},[_vm._v(" AddProfiles ")]):_vm._e(),_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" save ")]),(_vm.instant.id)?_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":_vm.deleteItem}},[_vm._v(" delete ")]):_vm._e(),_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":_vm.close}},[_vm._v(" close ")])],1)],1),_c('InstantmessageTranslationDetail',{attrs:{"visible":_vm.dialog_instantmessageTranslation,"is-host":_vm.isHost,"is-new":_vm.isNewTranslation,"data":_vm.selected_translation},on:{"close":function($event){_vm.dialog_instantmessageTranslation = false},"reload":_vm.loadInstantmessage}}),_c('CloneToDialog',{attrs:{"visible":_vm.dialog_clone_to,"project-id":_vm.selected_project_id},on:{"close":function($event){_vm.dialog_clone_to = false},"cloneto":_vm.cloneto}}),_c('CloneFromDialog',{attrs:{"visible":_vm.dialog_clone_from,"project-id":_vm.selected_project_id},on:{"close":function($event){_vm.dialog_clone_from = false},"clonefrom":_vm.clonefrom}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }