import axios from 'axios'
import store from '@/store'

class GlobalsServiceClass {

  async loadRoles() {
    try {
      const {data} = await axios.get('/api/globals/roles')
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async loadLanguages() {
    try {
      const {data} = await axios.get('/api/language/')
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

}

const globalService = new GlobalsServiceClass()
export default globalService
