export default class InstantmessageTranslation {
    id = null;
    instantmessage_id = null;
    title = null;
    description = null;
    language_id = null;


    constructor(instantmessage_id) {
        this.instantmessage_id = instantmessage_id;
    }
}
