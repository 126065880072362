<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        Translation
      </v-card-title>
      <v-card-text>
        <v-form ref="translation">
          <v-text-field
            v-model="translation.title"
            label="title"
            hint="only displayed internally"
            :rules="[rules.required()]"
          />
          <v-textarea
            v-model="translation.description"
            rows="5"
            label="displaytext"
            :rules="[rules.required()]"
          />
          <v-select
            v-model="translation.language_id"
            label="language"
            :items="languages"
            item-text="description"
            item-value="id"
            :rules="[rules.required()]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="translation.id"
          depressed
          small
          color="primary"
          @click="deleteItem()"
        >
          delete
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          @click="save()"
        >
          save
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          @click="close"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import _ from 'lodash';
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin';
import globalService from '@/services/Globals.service'
import instantmessageService from '@/services/Instantmessage.service'

export default {
  name: 'InstantmessageTranslationDetail',
  mixins: [ValidationRulesMixin],
  props: {
    visible: Boolean,
    isHost: Boolean,
    isNew: Boolean,
    data: {
      type: Object,
      default: Object
    },

  },
  data() {
    return {
      loading_languages: false,
      translation: {},
      languages: [],
    }
  },
  computed: {
    ...mapState('performance', []),
    ...mapState('instantmessage', ['instantmessageTranslation']),
    ...mapGetters('performance', ['getProjectLanguages'])
  },
  watch: {
    visible(val) {
      if (val) {
        this.cloneData();
        this.loadLanguages()
      }
    }
  },
  mounted() {
  },
  methods: {
    async loadLanguages() {
      this.loading_languages = true
      this.languages = await globalService.loadLanguages()
      this.loading_languages = false
    },

    ...mapActions('instantmessage', ['addTranslation', 'updateTranslation']),
    ...mapActions('performance', ['removeTranslation']),
    ...mapMutations('instantmessage', ['setDialogsTranslation']),

    cloneData() {
      this.translation = _.clone(this.data)
    },
    close() {
      this.$emit('close')
    },
    async save() {
      if (this.$refs.translation.validate()) {
        if (this.isNew) {
          console.log(this.translation)
          try {
            await instantmessageService.addTranslation(this.translation.instantmessage_id, this.translation)
            this.$toast('Translation created', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            this.$emit('reload')
            this.$emit('close')
          } catch (e) {
            this.$toast('create failed', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }
        } else {
          try {
            await instantmessageService.updateTranslation(this.translation.instantmessage_id, this.translation.id, this.translation)
            this.$toast('Translation updated', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            this.$emit('reload')
            this.$emit('close')
          } catch (e) {
            this.$toast('update failed', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }
        }
      }
    },

    async deleteItem() {
      this.$confirm('delete translation?', {
        type: 'delete'
      })
        .then(async value => {
          if (value) {
            try {
              await instantmessageService.deleteTranslation(this.translation.instantmessage_id, this.translation.id)
              this.$toast('Translation deleted', {
                type: 'success',
                top: true,
                right: true,
                timeout: 3000,
              })
              this.$emit('reload')
              this.$emit('close')
            } catch (e) {
              this.$toast('delete failed', {
                type: 'error',
                top: true,
                right: true,
                timeout: 3000,
              })
            }
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
