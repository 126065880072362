import axios from 'axios'
import store from '@/store'

class InstantmessageServiceClass {
  async loadInstantmessagesFromHost(host_id) {
    if (!host_id) {
      host_id = store.state.admin.host_id
    }
    try {
      const {data} = await axios.get(`/api/instantmessage/${host_id}`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async loadInstantmessageById(instantmessage_id) {
    const host_id = store.state.admin.host_id
    try {
      const {data} = await axios.get(`/api/instantmessage/${host_id}/instantmessage/${instantmessage_id}`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async create(payload) {
    const host_id = store.state.admin.host_id

    try {
      const {data} = await axios.post(`/api/instantmessage/${host_id}`, payload)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async delete(instantmessage_id) {
    const host_id = store.state.admin.host_id

    try {
      const {data} = await axios.delete(`/api/instantmessage/${host_id}/instantmessage/${instantmessage_id}`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async update(instantmessage_id, payload) {
    const host_id = store.state.admin.host_id

    try {
      const {data} = await axios.put(`/api/instantmessage/${host_id}/instantmessage/${instantmessage_id}`, payload)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }


  async addTranslation(instantmessage_id, payload) {
    const host_id = store.state.admin.host_id
    try {
      const {data} = await axios.post(`/api/instantmessage/${host_id}/instantmessage/${instantmessage_id}/translation`, payload)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async updateTranslation(instantmessage_id, translation_id, payload) {
    const host_id = store.state.admin.host_id
    try {
      const {data} = await axios.put(`/api/instantmessage/${host_id}/instantmessage/${instantmessage_id}/translation/${translation_id}`, payload)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async deleteTranslation(instantmessage_id, translation_id) {
    const host_id = store.state.admin.host_id
    try {
      const {data} = await axios.delete(`/api/instantmessage/${host_id}/instantmessage/${instantmessage_id}/translation/${translation_id}`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  //PROJECT INSTANT MESSAGES

  async loadInstantmessagesFromProject(project_id) {
    const host_id = store.state.admin.host_id

    try {
      const {data} = await axios.get(`/api/instantmessage/${host_id}/project/${project_id}`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async addInstantmessageFromHostpool(project_id, instant_id) {
    const host_id = store.state.admin.host_id

    try {
      const {data} = await axios.post(`/api/instantmessage/${host_id}/instantmessage/${instant_id}/copytoproject/${project_id}`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }


  async createTranslationsPerProfile(instant_id, project_id) {
    const host_id = store.state.admin.host_id

    try {
      const {data} = await axios.post(`/api/instantmessage/${host_id}/instantmessage/${instant_id}/createtranslationsfromprojectprofiles/${project_id}`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async publish(instant_id) {
    const host_id = store.state.admin.host_id

    try {
      const {data} = await axios.put(`/api/instantmessage/${host_id}/instantmessage/${instant_id}/publish`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async unpublish(instant_id) {
    const host_id = store.state.admin.host_id

    try {
      const {data} = await axios.put(`/api/instantmessage/${host_id}/instantmessage/${instant_id}/unpublish`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }
}

const instantmessageService = new InstantmessageServiceClass()
export default instantmessageService
