import moment from 'moment';
export default class Instantmessage {
    id = null;
    projectId = null;
    hostId = null;
    title = null;
    description = null;
    instantmessageTranslations = [];
    active = 0;


    constructor(projectId, hostId) {
        this.projectId = projectId;
        this.hostId = hostId;
        this.title = moment().format('YYYY-MM-DD hh:mm:ss');
        this.active = false
    }
}
