<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="300px"
  >
    <v-card>
      <v-card-title>
        Clone To
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="clone"
          label="select profile"
          :items="profiles"
          :loading="loading_profiles"
          item-text="title"
          item-value="id"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn
          depressed
          small
          color="primary"
          @click="save"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          @click="close"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from 'vuex'
import projectService from '@/services/Project.service'

export default {
  name: 'CloneToDialog',
  props: {
    visible: Boolean,
    projectId: {
      type: Number,
      default: Number
    }
  },
  data: () => ({
    loading_profiles: false,
    clone: null,
    profiles: [],
  }),
  watch: {
    visible(val) {
      if (val) {
        this.loadProjectProfiles()
      }
    }
  },
  methods: {
    async loadProjectProfiles() {
      this.loading_profiles = true
      this.profiles = await projectService.getProfiles(this.projectId)
      this.loading_profiles = false
    },
    close() {
      this.$emit('close')
    },
    save() {
      this.$emit('cloneto', this.clone)
    }
  }
}
</script>

<style scoped>

</style>
